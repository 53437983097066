
import { AppConst } from '@/store/modules/constants'
import { VersionConstants } from '@/store/modules/version/constants'
import { Namespace, StandardObject } from '@/store/types'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'

declare var consoleLog: any
declare var consoleError: any

const modeConfirming = 0
// const modeSubmitting = 1
const modeResults = 2

@Component({})

export default class AppDeeplinkUpdaterVue extends Vue {
  @Prop({ type: String, default: '' })
  public currentVersionName!: string

  @State('latestVersion', { namespace: Namespace.Version })
  public latestVersion!: StandardObject

  @Action('updateBranchIODeeplinkDownloadUrl', { namespace: Namespace.Version })
  public updateBranchIODeeplinkDownloadUrl: any

  private isFormValid: boolean = false
  private app: VersionConstants.VersionDefaults = VersionConstants.VersionDefaults.DefaultApplication
  private currentMode: number = modeConfirming
  private requestsData: any[] = []
  private submissionResults: any[] = []
  private environment: string = process.env.VUE_APP_DASHBOARD_ENV || 'production'

  protected async created() {
    this.currentMode = modeConfirming
    this.determineDownloadUrls()
  }

  private get version(): any {
    const currentVersion = this.latestVersion[this.app]

    return currentVersion || {}
  }

  private determineDownloadUrls() {
    if (!this.currentVersionName) {
      return
    }

    this.requestsData = []
    for (const item of AppConst.appDownloadDeeplinks.jigspace) {
      if (item.platform === VersionConstants.PlatformsOptions.desktop) {
        let platformName = ''
        let dotExtension = ''
        if (item.variant === 'macos') {
          platformName = 'MacOS'
          dotExtension = '.zip'
        } else if (item.variant === 'win-zip') {
          platformName = 'Win'
          dotExtension = '.zip'
        } else if (item.variant === 'win') {
          platformName = 'Win'
          dotExtension = '.exe'
        } else {
          consoleError('unhandled platform type: ' + item.platform)
          return
        }

        // Format of a download target that hits JigSpace S3 directly and avoids CF:-
        // downloadTargetUrl: `https://jigspace-desktop.s3.amazonaws.com/${this.version.Version}/JigSpace_${platformName}_${this.version.Version}${dotExtension}`,
        // Format of a download target that hits the CloudFront cache of the S3 bucket:-
        // downloadTargetUrl: `https://dnkv99gmr79m7.cloudfront.net/${this.version.Version}/JigSpace_${platformName}_${this.version.Version}${dotExtension}`,
        // We use the CF cached target for faster download speeds.
        const req: any = {
          app: VersionConstants.VersionDefaults.DefaultApplication,
          platform: item.platform.toLowerCase(),
          variant: item.variant,
          downloadTargetUrl: `https://dnkv99gmr79m7.cloudfront.net/${this.version.Version}/JigSpace_${platformName}_${this.version.Version}${dotExtension}`,
        }
        if (this.environment !== 'production') {
          req.optionalTestingDeeplink = item.nonProdDeeplinkUrl
        }

        this.requestsData.push(req)
      }
    }
  }

  private closeForm() {
    this.$emit('on-app-deeplink-update-form-close')
  }

  private async submitUpdate() {
    for (const data of this.requestsData) {
      const res = await this.updateBranchIODeeplinkDownloadUrl(data)
      this.submissionResults.push({
        requestData: data,
        response: res,
      })
      if (res.status === 'success') {
        consoleLog('Successfully updated branch IO deeplink download URL for variant: ' + data.variant)
      } else {
        consoleError('FAILED to update branch IO deeplink download URL for variant: ' + data.variant)
      }
    }
    this.currentMode = modeResults
  }
}
