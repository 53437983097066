
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})

export default class ExpansionPanelsVue extends Vue {
  @Prop({ type: String, default: 'Click to see details' })
  public title!: string
  @Prop({ type: [String, Object], default: '' })
  public bodyCopy!: string
  @Prop({ type: Boolean, default: false })
  public code!: boolean
}
